const state = {
    colors: [],
    isLoadingColors: false
};

const getters = {
    colorsColors: state => state.colors,
    colorsIsLoadingColors: state => state.isLoadingColors,
};

const COLORS_FETCH_COLORS_STARTED = "COLORS_FETCH_COLORS_STARTED";
const COLORS_FETCH_COLORS_SUCCEED = "COLORS_FETCH_COLORS_SUCCEED";
const COLORS_FETCH_COLORS_FAILED = "COLORS_FETCH_COLORS_FAILED";

const actions = {
    colorsFetchColors({ commit }) {
        commit(COLORS_FETCH_COLORS_STARTED);
        this._vm.axios
            .get("/products/colors")
            .then(({ data }) => {
                commit(COLORS_FETCH_COLORS_SUCCEED, data.colors);
            })
            .catch(() => {
                commit(COLORS_FETCH_COLORS_FAILED);
            });
    },
};

const mutations = {
    [COLORS_FETCH_COLORS_STARTED](state) {
        state.isLoadingColors = true;
    },
    [COLORS_FETCH_COLORS_SUCCEED](state, colors) {
        state.colors = colors
        state.isLoadingColors = false;
    },
    [COLORS_FETCH_COLORS_FAILED](state) {
        state.isLoadingColors = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
