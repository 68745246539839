import router from '@/router'

const state = {
    user: null,
    token: null,
    isLoadingUser: false,
    isLogingIn: false,
    socketStatus: null
};

const getters = {
    authToken: state => state.token,
    authUser: state => state.user,
    authIsLogged: state => state.token !== null,
    authIsLoadingUser: state => state.isLoadingUser,
    authIsLogingIn: state => state.isLogingIn,
    authSocketStatus: state => state.socketStatus
};

const AUTH_LOGIN_STARTED = "AUTH_LOGIN_STARTED";
const AUTH_LOGIN_SUCCEED = "AUTH_LOGIN_SUCCEED";
const AUTH_LOGIN_FAILED = "AUTH_LOGIN_FAILED";
const AUTH_LOGOUT_STARTED = "AUTH_LOGOUT_STARTED";
const AUTH_LOGOUT_SUCCEED = "AUTH_LOGOUT_SUCCEED";
const AUTH_LOGOUT_FAILED = "AUTH_LOGOUT_FAILED";
const AUTH_FETCH_USER_STARTED = "AUTH_FETCH_USER_STARTED";
const AUTH_FETCH_USER_SUCCEED = "AUTH_FETCH_USER_SUCCEED";
const AUTH_FETCH_USER_FAILED = "AUTH_FETCH_USER_FAILED";
const AUTH_SET_SOCKET_STATUS = 'AUTH_SET_SOCKET_STATUS';

const actions = {
    authInvalidate({ commit }) {
        commit(AUTH_LOGOUT_SUCCEED)
    },
    login({ commit }, model) {
        commit(AUTH_LOGIN_STARTED);
        this._vm.axios
            .post(process.env.NODE_ENV === 'production' ? 'https://staging.tu.gimmickco.com/api/token' : "http://gimmick.test/api/token",
            {
                ...model,
                storefront_id: process.env.VUE_APP_STOREFRONT_ID
            }
        )
            .then(({ data }) => {
                commit(AUTH_LOGIN_SUCCEED, data);
                this.dispatch('productsFetchCategories')
                this.dispatch('constantsFetchConstants')
                this.dispatch('pointsFetchBalance')
                const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
                if (urlParams.has("callbackUrl")) {
                    router.push(urlParams.get("callbackUrl"))
                } else {
                    router.push('/store')
                }
            })
            .catch(() => {
                commit(AUTH_LOGIN_FAILED);
            });
    },
    register({ commit }, model) {
        commit(AUTH_LOGIN_STARTED);
        this._vm.axios
            .post(process.env.NODE_ENV === 'production' ? 'https://staging.tu.gimmickco.com/api/register' : "http://gimmick.test/api/register",
            {
                ...model,
                storefront_id: process.env.VUE_APP_STOREFRONT_ID
            }
        )
            .then(({ data }) => {
                commit(AUTH_LOGIN_SUCCEED, data);
                this.dispatch('productsFetchCategories')
                this.dispatch('constantsFetchConstants')
                this.dispatch('pointsFetchBalance')
                router.push('/store')
            })
            .catch(() => {
                commit(AUTH_LOGIN_FAILED);
            });
    },
    logout({ commit }) {
        commit(AUTH_LOGOUT_STARTED);
        this._vm.axios
            .get(process.env.NODE_ENV === 'production' ? 'https://staging.tu.gimmickco.com/api/logout' : "http://gimmick.test/api/logout")
            .then(() => {
                commit(AUTH_LOGOUT_SUCCEED);
                router.push("/login");
            })
            .catch(() => {
                commit(AUTH_LOGOUT_FAILED);
            });
    },
    authFetchUser({ commit }) {
        commit(AUTH_FETCH_USER_STARTED);
        this._vm.axios
            .get("/user")
            .then(({ data }) => {
                commit(AUTH_FETCH_USER_SUCCEED, data);
            })
            .catch(() => {
                commit(AUTH_FETCH_USER_FAILED);
            });
    },
    setSocketStatus({ commit }, status) {
        commit(AUTH_SET_SOCKET_STATUS, status)
    }
};

const mutations = {
    [AUTH_LOGIN_STARTED](state) {
        state.isLogingIn = true;
    },
    [AUTH_LOGIN_SUCCEED](state, data) {
        state.token = data.token
        state.user = data.user
        state.isLogingIn = false;
    },
    [AUTH_LOGIN_FAILED](state) {
        state.isLogingIn = false;
    },
    [AUTH_LOGOUT_STARTED](state) {
        state.isLogingIn = true;
    },
    [AUTH_LOGOUT_SUCCEED](state) {
        state.user = null;
        state.token = null;
        state.isLogingIn = false;
    },
    [AUTH_LOGOUT_FAILED](state) {
        state.isLogingIn = false;
    },
    [AUTH_FETCH_USER_STARTED](state) {
        state.isLoadingUser = true;
    },
    [AUTH_FETCH_USER_SUCCEED](state, data) {
        state.user = data
        state.isLoadingUser = false;
    },
    [AUTH_FETCH_USER_FAILED](state) {
        state.isLoadingUser = false;
    },
    [AUTH_SET_SOCKET_STATUS](state, status) {
        state.socketStatus = status
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
