const state = {
    countries: [],
    isLoadingCountries: false
};

const getters = {
    countriesCountries: state => state.countries,
    countriesIsLoadingCountries: state => state.isLoadingCountries,
};

const COUNTRIES_FETCH_COUNTRIES_STARTED = "COUNTRIES_FETCH_COUNTRIES_STARTED";
const COUNTRIES_FETCH_COUNTRIES_SUCCEED = "COUNTRIES_FETCH_COUNTRIES_SUCCEED";
const COUNTRIES_FETCH_COUNTRIES_FAILED = "COUNTRIES_FETCH_COUNTRIES_FAILED";

const actions = {
    countriesFetchCountries({ commit }) {
        commit(COUNTRIES_FETCH_COUNTRIES_STARTED);
        this._vm.axios
            .get("/countries")
            .then(({ data }) => {
                commit(COUNTRIES_FETCH_COUNTRIES_SUCCEED, data.countries);
            })
            .catch(() => {
                commit(COUNTRIES_FETCH_COUNTRIES_FAILED);
            });
    },
};

const mutations = {
    [COUNTRIES_FETCH_COUNTRIES_STARTED](state) {
        state.isLoadingCountries = true;
    },
    [COUNTRIES_FETCH_COUNTRIES_SUCCEED](state, countries) {
        state.countries = countries
        state.isLoadingCountries = false;
    },
    [COUNTRIES_FETCH_COUNTRIES_FAILED](state) {
        state.isLoadingCountries = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
