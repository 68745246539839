const state = {
  userQuotables: {},
  isLoadingUserQuotables: false
};

const getters = {
  quotablesQuotables: state => state.userQuotables,
  quotablesIsLoadingQuotables: state => state.isLoadingUserQuotables,
};

const QUOTABLES_FETCH_USER_QUOTABLES_STARTED = "QUOTABLES_FETCH_USER_QUOTABLES_STARTED";
const QUOTABLES_FETCH_USER_QUOTABLES_SUCCEED = "QUOTABLES_FETCH_USER_QUOTABLES_SUCCEED";
const QUOTABLES_FETCH_USER_QUOTABLES_FAILED = "QUOTABLES_FETCH_USER_QUOTABLES_FAILED";

const actions = {
  quotablesFetchUserQuotables({ commit }) {
      commit(QUOTABLES_FETCH_USER_QUOTABLES_STARTED);
      this._vm.axios
          .get("/quotes")
          .then(({ data }) => {
              commit(QUOTABLES_FETCH_USER_QUOTABLES_SUCCEED, data.userQuotables);
          })
          .catch(() => {
              commit(QUOTABLES_FETCH_USER_QUOTABLES_FAILED);
          });
  },
};

const mutations = {
  [QUOTABLES_FETCH_USER_QUOTABLES_STARTED](state) {
      state.isLoadingUserQuotables = true;
  },
  [QUOTABLES_FETCH_USER_QUOTABLES_SUCCEED](state, userQuotables) {
      state.userQuotables = userQuotables
      state.isLoadingUserQuotables = false;
  },
  [QUOTABLES_FETCH_USER_QUOTABLES_FAILED](state) {
      state.isLoadingUserQuotables = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
