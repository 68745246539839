const state = {
    printTechniques: [],
    isLoadingPrintTechniques: false
};

const getters = {
    printingPrintTechniques: state => state.printTechniques,
    printingIsLoadingPrintTechniques: state => state.isLoadingPrintTechniques,
};

const PRINTING_FETCH_PRINT_TECHNIQUES_STARTED = "PRINTING_FETCH_PRINT_TECHNIQUES_STARTED";
const PRINTING_FETCH_PRINT_TECHNIQUES_SUCCEED = "PRINTING_FETCH_PRINT_TECHNIQUES_SUCCEED";
const PRINTING_FETCH_PRINT_TECHNIQUES_FAILED = "PRINTING_FETCH_PRINT_TECHNIQUES_FAILED";

const actions = {
    printingFetchPrintTechniques({ commit }) {
        commit(PRINTING_FETCH_PRINT_TECHNIQUES_STARTED);
        this._vm.axios
            .get("/printing/print-techniques")
            .then(({ data: { printTechniques } }) => {
                commit(PRINTING_FETCH_PRINT_TECHNIQUES_SUCCEED, printTechniques);
            })
            .catch(() => {
                commit(PRINTING_FETCH_PRINT_TECHNIQUES_FAILED);
            });
    },
};

const mutations = {
    [PRINTING_FETCH_PRINT_TECHNIQUES_STARTED](state) {
        state.isLoadingPrintTechniques = true;
    },
    [PRINTING_FETCH_PRINT_TECHNIQUES_SUCCEED](state, printTechniques) {
        state.printTechniques = printTechniques
        state.isLoadingPrintTechniques = false;
    },
    [PRINTING_FETCH_PRINT_TECHNIQUES_FAILED](state) {
        state.isLoadingPrintTechniques = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
