<template>
    <div class="uk-flex uk-flex-right uk-flex-wrap-reverse">
        <select
            :id="itemsPerPageId"
            class="uk-select uk-border-rounded uk-width-small"
            v-model="$parent[itemsPerPage]"
            :disabled="isFetching"
        >
            <option disabled>Items por página</option>
            <option :value="10">10</option>
            <option :value="20">20</option>
            <option :value="50">50</option>
        </select>
        <div
            class="pagination-button-container uk-button-group uk-border-rounded uk-overflow-hidden uk-width-large uk-margin-small-left"
        >
            <button
                class="uk-button uk-button-primary uk-padding-remove pagination-button "
                :disabled="prevPageUrl === null || isFetching"
                @click="fetchPage(null, prevPageUrl)"
            >
                <span uk-icon="icon: chevron-left"></span>
            </button>
            <button
                class="uk-button uk-width-expand bg-gray-100"
                disabled
            >
                <span uk-spinner="ratio: 0.7" v-if="isFetching"></span>
                <span v-else>Página {{ currentPage }} de {{ lastPage }}</span>
            </button>
            <button
                class="uk-button uk-button-primary uk-padding-remove pagination-button"
                :disabled="nextPageUrl === null || isFetching"
                @click="fetchPage(null, nextPageUrl)"
            >
                <span uk-icon="icon: chevron-right"></span>
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        itemsPerPageId: {
            type: String,
            default: "itemsPerPageId",
        },
        prevItemsBtnId: {
            type: String,
            default: "prevItemsBtnId",
        },
        nextItemsBtnId: {
            type: String,
            default: "nextItemsBtnId",
        },
        prevPageUrl: String,
        isFetching: Boolean,
        currentPage: [String, Number],
        lastPage: [String, Number],
        nextPageUrl: String,
        methodName: {
            type: String,
            default: "fetchPage",
        },
        itemsPerPage: {
            type: String,
            default: "itemsPerPage",
        },
    },
    methods: {
        fetchPage: function (event, pageUrl) {
            this.$parent[this.methodName](null, pageUrl);
        },
    },
};
</script>

<style>
.pagination-button-container {
   max-width: 200px;
}
</style>
