const state = {
    categories: [],
    isLoadingCategories: false
};

const getters = {
    productsCategories: state => state.categories,
    productsIsLoadingCategories: state => state.isLoadingCategories,
};

const PRODUCTS_FETCH_CATEGORIES_STARTED = "PRODUCTS_FETCH_CATEGORIES_STARTED";
const PRODUCTS_FETCH_CATEGORIES_SUCCEED = "PRODUCTS_FETCH_CATEGORIES_SUCCEED";
const PRODUCTS_FETCH_CATEGORIES_FAILED = "PRODUCTS_FETCH_CATEGORIES_FAILED";

const actions = {
    productsFetchCategories({ commit }) {
        commit(PRODUCTS_FETCH_CATEGORIES_STARTED);
        this._vm.axios
            .get("/products/categories")
            .then(({ data }) => {
                commit(PRODUCTS_FETCH_CATEGORIES_SUCCEED, data);
            })
            .catch(() => {
                commit(PRODUCTS_FETCH_CATEGORIES_FAILED);
            });
    },
};

const mutations = {
    [PRODUCTS_FETCH_CATEGORIES_STARTED](state) {
        state.isLoadingCategories = true;
    },
    [PRODUCTS_FETCH_CATEGORIES_SUCCEED](state, categories) {
        state.categories = categories
        state.isLoadingCategories = false;
    },
    [PRODUCTS_FETCH_CATEGORIES_FAILED](state) {
        state.isLoadingCategories = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
