const state = {
  storefront: {},
  isLoadingStorefront: false
};

const getters = {
  storefrontStorefront: state => state.storefront,
  storefrontIsLoadingStorefront: state => state.isLoadingStorefront,
};

const STOREFRONT_FETCH_STOREFRONT_STARTED = "STOREFRONT_FETCH_STOREFRONT_STARTED";
const STOREFRONT_FETCH_STOREFRONT_SUCCEED = "STOREFRONT_FETCH_STOREFRONT_SUCCEED";
const STOREFRONT_FETCH_STOREFRONT_FAILED = "STOREFRONT_FETCH_STOREFRONT_FAILED";

const actions = {
  storefrontFetchStorefront({ commit }) {
      commit(STOREFRONT_FETCH_STOREFRONT_STARTED);
      this._vm.axios
          .get("/storefronts")
          .then(({ data }) => {
              commit(STOREFRONT_FETCH_STOREFRONT_SUCCEED, data.storefront);
          })
          .catch(() => {
              commit(STOREFRONT_FETCH_STOREFRONT_FAILED);
          });
  },
};

const mutations = {
  [STOREFRONT_FETCH_STOREFRONT_STARTED](state) {
      state.isLoadingStorefront = true;
  },
  [STOREFRONT_FETCH_STOREFRONT_SUCCEED](state, storefront) {
      state.storefront = storefront
      state.isLoadingStorefront = false;
  },
  [STOREFRONT_FETCH_STOREFRONT_FAILED](state) {
      state.isLoadingStorefront = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
