const state = {
    constants: [],
    isLoadingConstants: false
};

const getters = {
    constantsConstants: state => state.constants,
    constantsIsLoadingConstants: state => state.isLoadingConstants,
};

const CONSTANTS_FETCH_CONSTANTS_STARTED = "CONSTANTS_FETCH_CONSTANTS_STARTED";
const CONSTANTS_FETCH_CONSTANTS_SUCCEED = "CONSTANTS_FETCH_CONSTANTS_SUCCEED";
const CONSTANTS_FETCH_CONSTANTS_FAILED = "CONSTANTS_FETCH_CONSTANTS_FAILED";

const actions = {
    constantsFetchConstants({ commit }) {
        commit(CONSTANTS_FETCH_CONSTANTS_STARTED);
        this._vm.axios
            .get("/constants")
            .then(({ data }) => {
                commit(CONSTANTS_FETCH_CONSTANTS_SUCCEED, data);
            })
            .catch(() => {
                commit(CONSTANTS_FETCH_CONSTANTS_FAILED);
            });
    },
};

const mutations = {
    [CONSTANTS_FETCH_CONSTANTS_STARTED](state) {
        state.isLoadingConstants = true;
    },
    [CONSTANTS_FETCH_CONSTANTS_SUCCEED](state, constants) {
        state.constants = constants
        state.isLoadingConstants = false;
    },
    [CONSTANTS_FETCH_CONSTANTS_FAILED](state) {
        state.isLoadingConstants = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
