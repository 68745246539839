import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth";
import products from "./products";
import constants from "./constants";
import colors from "./colors";
import countries from "./countries";
import printing from "./printing";
import storefront from "./storefront";
import quotables from "./quotables";
import points from "./points";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    countries,
    colors,
    constants,
    products,
    printing,
    points,
    storefront,
    quotables
  },
  plugins: [
    new VuexPersistence({
      key: 'gimmick-frontend',
      storage: window.localStorage,
      reducer: state => ({
        auth: state.auth,
        constants: state.constants,
        storefront: state.storefront,
        products: {
          categories: state.products.categories
        }
      }),
    }).plugin,
  ],
})
