<template>
  <div class="uk-flex uk-flex-middle uk-border-rounded uk-overflow-hidden">
    <input
      :disabled="disabled"
      class="uk-input uk-text-center uk-width-2-3"
      type="text"
      v-model="internalValue"
    />
    <button
      :disabled="disabled"
      class="uk-button uk-width-1-6"
      :class="{ 'uk-button-primary': mode === 'lb' }"
      @click="setLb"
    >
      lb
    </button>
    <button
      :disabled="disabled"
      class="uk-button uk-width-1-6"
      :class="{ 'uk-button-primary': mode === 'kg' }"
      @click="setKg"
    >
      kg
    </button>
  </div>
</template>

<script>
const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export default {
  name: "WeightInput",

  props: ["value", "disabled"],

  data() {
    return {
      id: uuidv4(),
      internalValue: this.value,
      mode: "lb",
    };
  },

  methods: {
    setLb() {
      if (this.mode !== "lb") {
        this.mode = "lb";
        this.internalValue = this.internalValue / 0.453592;
      }
    },
    setKg() {
      if (this.mode !== "kg") {
        this.mode = "kg";
        this.internalValue = this.internalValue * 0.453592;
      }
    },
    update() {
      const value =
        this.mode === "lb" ? this.internalValue : this.internalValue / 0.453592;
      this.$emit("input", value);
    },
  },

  watch: {
    internalValue() {
      this.update();
    },
  },
};
</script>
