<template>
  <div class="uk-flex uk-flex-middle uk-border-rounded uk-overflow-hidden">
    <input
      :disabled="disabled"
      class="uk-input uk-text-center uk-width-2-3"
      type="text"
      v-model="internalValue"
    />
    <button
      :disabled="disabled"
      class="uk-button uk-width-1-6"
      :class="{ 'uk-button-primary': mode === 'in' }"
      @click="setIn"
    >
      in
    </button>
    <button
      :disabled="disabled"
      class="uk-button uk-width-1-6"
      :class="{ 'uk-button-primary': mode === 'cm' }"
      @click="setCm"
    >
      cm
    </button>
  </div>
</template>

<script>
const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export default {
  name: "SizeInput",

  props: ["value", "disabled"],

  data() {
    return {
      id: uuidv4(),
      internalValue: this.value,
      mode: "in",
    };
  },

  methods: {
    setIn() {
      if (this.mode !== "in") {
        this.mode = "in";
        this.internalValue = this.internalValue / 2.54;
      }
    },
    setCm() {
      if (this.mode !== "cm") {
        this.mode = "cm";
        this.internalValue = this.internalValue * 2.54;
      }
    },
    update() {
      const value =
        this.mode === "in" ? this.internalValue : this.internalValue / 2.54;
      this.$emit("input", value);
    },
  },

  watch: {
    internalValue() {
      this.update();
    },
  },
};
</script>
