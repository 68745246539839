<template>
  <div>
    <div class="uk-margin-top uk-flex uk-flex-between uk-flex-middle">
      <div class="uk-text-bold">Parametrización de constantes</div>
      <button
        class="uk-button uk-button-primary uk-border-rounded"
        href="#edit-parametrization-modal"
        uk-toggle
        @click="setNewMode"
      >
        Crear nueva parametrización
      </button>
    </div>
    <table
      v-if="constantValues.length !== 0"
      class="uk-table uk-table-striped uk-table-middle uk-table-hover uk-table-divider uk-margin-remove"
    >
      <thead>
        <tr>
          <th>Constante</th>
          <th>Valor parametrizado</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="constantValue in constantValues" :key="constantValue.id">
          <td>{{ constantValue.constant.name }}</td>
          <td>{{ constantValue.value }}</td>
          <td>
            <div class="uk-text-right">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  class="uk-button uk-button-default"
                  uk-icon="pencil"
                  href="#edit-parametrization-modal"
                  uk-toggle
                  @click="setEditMode(constantValue)"
                ></button>
                <button
                  class="uk-button uk-button-default"
                  uk-icon="trash"
                  href="#delete-parametrization-modal"
                  uk-toggle
                  @click="setEditMode(constantValue)"
                ></button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="uk-text-center" v-else>
      No se han agregado parametrizaciones para este item.
    </div>

    <div
      id="edit-parametrization-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Crear</span>
          <span v-else>Editar</span>
          parametrización
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider name="constant" rules="" v-slot="{ errors }">
            <div class="uk-margin-top">Constante</div>
            <v-select
              id="constant-select"
              v-model="model.constant_id"
              class="uk-input uk-border-rounded"
              :options="constants"
              :reduce="(constant) => constant.id"
              :getOptionLabel="(option) => option.name"
            ></v-select>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>

          <ValidationProvider name="name" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">Valor</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="name"
              v-model="model.value"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoading"
              @click="mode === 'new' ? store() : update()"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else>
                <span v-if="mode === 'new'">Crear</span>
                <span v-else>Editar</span>
                parametrización
              </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div
      id="delete-parametrization-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
        v-if="selectedParametrization"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          ¿Está seguro que desea eliminar la parametrización para
          {{ selectedParametrization.constant.name }}?
        </div>

        <div class="uk-text-center uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-border-rounded"
            @click="destroy"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Eliminar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
const blankModel = {
  constant_id: null,
  value: null,
};

export default {
  name: "BusinessesShow",

  props: [
    "parametrizableType",
    "parametrizableId",
    "constantValues",
    "constants",
    "refetchFunction",
  ],

  data() {
    return {
      mode: "new",
      model: {
        ...this._.cloneDeep(blankModel),
        parametrizable_id: this.parametrizableId,
        parametrizable_type: this.parametrizableType,
      },
      selectedParametrization: null,
      isFetching: false,
      isLoading: false,
    };
  },

  methods: {
    resetModel() {
      this.model = {
        ...this._.cloneDeep(blankModel),
        parametrizable_id: this.parametrizableId,
        parametrizable_type: this.parametrizableType,
      };
    },
    setNewMode() {
      this.mode = "new";
      this.selectedParametrization = null;
      this.resetModel();
    },
    setEditMode(parametrization) {
      this.mode = "edit";
      this.selectedParametrization = parametrization;
      this.model.constant_id = parametrization.constant_id;
      this.model.value = parametrization.value;
    },
    store() {
      this.isLoading = true;
      this.axios
        .post("/parametrizations", this.model)
        .then(() => {
          this.refetchFunction();
          UIkit.modal("#edit-parametrization-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    update() {
      this.isLoading = true;
      this.axios
        .put(
          "/parametrizations/" + this.selectedParametrization.id.toString(),
          this.model
        )
        .then(() => {
          this.refetchFunction();
          UIkit.modal("#edit-parametrization-modal").hide();
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    destroy() {
      this.isLoading = true;
      this.axios
        .delete(
          "/parametrizations/" + this.selectedParametrization.id.toString()
        )
        .then(() => {
          this.refetchFunction();
          UIkit.modal("#delete-parametrization-modal").hide();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
